class EventTracker {
  constructor() {
    this.user_id = null;
    this.endpoint = "/ma/s";
    this.eventQueue = [];
    this.MAX_EVENTS = 30; // Send events after accumulating N events
    this.FETCH_INTERVAL = 2; // Send events every N seconds
    this.clickConfig = [];
    this.impressionConfig = [];
    this.observer = null;
    this.mutationObserver = null;
    this.timeoutCount = 0; // Number of timeouts
    this.MAX_TIMEOUT_COUNT = 2; // Maximum allowable timeouts
    this.TIMEOUT_MS = 2000; // Timeout duration in milliseconds
    this.fetch_lock = false;
    this.debugMode = navigator.userAgent.includes("debug-mode"); // Enable debug mode if user agent contains "debug-mode"
  }

  /**
   * Initialization method
   * @param {Object} config - Initial configuration
   */
  init(config) {
    if (config.endpoint) {
      this.endpoint = config.endpoint;
    }
    if (config.click) {
      this.clickConfig = config.click;
    }
    if (config.impression) {
      this.impressionConfig = config.impression;
    }
    this.user_id = this.getUserId();

    this.addEvent("page_view");
    this.observeClicks();
    this.observeImpressions();

    // Periodically send events every FETCH_INTERVAL seconds
    this.startFetchInterval();
    // Send events before leaving the page using beacon
    window.addEventListener("beforeunload", () => this.sendBeaconRequest());
  }

  /**
   * Add an event to the queue
   * @param {string} eventType - Type of event
   * @param {Object} params - Additional event parameters
   */
  addEvent(eventType, params = {}) {
    const eventParams = Object.entries(params).map(([key, value]) => ({ key, value }));

    const eventData = {
      event_timestamp: Date.now(), // UNIX timestamp in ms
      event_name: eventType,
      page_url: window.location.href,
      user_id: this.user_id,
      event_params: eventParams,
    };

    this.eventQueue.push(eventData);

    if (this.debugMode) {
      console.log("Event added:", eventData);
    }

    if (this.eventQueue.length >= this.MAX_EVENTS) {
      this.sendFetchRequest();
    }
  }

  /**
   * Send events using sendBeacon before leaving the page
   */
  sendBeaconRequest() {
    if (this.eventQueue.length === 0) return;
    const payload = JSON.stringify(this.eventQueue);
    if (!this.fetch_lock) {
      navigator.sendBeacon(this.endpoint, payload);
      if (this.debugMode) {
        console.log("Beacon request sent:", payload);
      }
    }
  }

  /**
   * Send events using Fetch API.
   * Instead of clearing the entire queue, only remove the sent events.
   */
  sendFetchRequest() {
    if (this.eventQueue.length === 0 || this.fetch_lock) return;
    // Clone the current queue as eventsToSend without affecting new additions
    const eventsToSend = this.eventQueue.slice();
    this.fetch_lock = true;
    const payload = JSON.stringify(eventsToSend);
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), this.TIMEOUT_MS);

    fetch(this.endpoint, {
      method: "POST",
      body: payload,
      keepalive: true,
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      signal: controller.signal
    }).then(response => response.text())
      .then(text => {
        clearTimeout(timeoutId);
        // Remove only the events that were sent
        this.eventQueue = this.eventQueue.filter(event => !eventsToSend.includes(event));
        this.fetch_lock = false;
        this.timeoutCount = 0; // Reset timeout count

        if (this.debugMode) {
          console.log("Fetch request successful:", text);
        }
        // If new events were added while sending, attempt to send them immediately
        if (this.eventQueue.length > 0) {
          this.sendFetchRequest();
        }
      })
      .catch(error => {
        clearTimeout(timeoutId);
        if (error.name === "AbortError") {
          console.error("Timeout occurred while sending request.");
          this.timeoutCount++;
          if (this.timeoutCount >= this.MAX_TIMEOUT_COUNT) {
            console.warn("Max timeout limit reached. Resetting event queue.");
            this.eventQueue = []; // Reset entire queue if repeated timeouts occur
            this.timeoutCount = 0;
          }
        } else {
          console.error("Fetch error:", error);
        }
        this.fetch_lock = false;
      });
  }

  /**
   * Observe click events using event delegation.
   * This method handles both configured selectors and external links.
   */
  observeClicks() {
    document.body.addEventListener("click", (event) => {
      let handled = false;
      // Loop through click configurations
      this.clickConfig.forEach(config => {
        const element = event.target.closest(config.selector);
        if (element) {
          handled = true;
          const params = {};
          config.event_parameters.forEach(param => {
            params[param] = element.getAttribute(param) || null;
          });
          // Get element's class name
          params["element_class"] = element.className;
          // Determine link URL:
          // If the element itself is an <a>, use its href; otherwise, find the closest <a> ancestor.
          const anchor = element.tagName.toLowerCase() === "a" ? element : element.closest("a");
          params["link_url"] = anchor ? anchor.getAttribute("href") : null;
          this.addEvent("click", params);
        }
      });

      // If no configured selector handled the click, check if it is an external link
      if (!handled) {
        const anchor = event.target.closest("a");
        if (anchor) {
          try {
            const linkUrl = new URL(anchor.href, window.location.href);
            if (linkUrl.hostname !== window.location.hostname) {
              // External link detected, always log click event
              const params = {};
              params["element_class"] = anchor.className;
              params["link_url"] = anchor.getAttribute("href") || null;
              // You can add any default parameters here if needed
              this.addEvent("click", params);
            }
          } catch (e) {
            // If URL parsing fails, do nothing
          }
        }
      }
    });
  }


  /**
   * Observe impression events
   */
  observeImpressions() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.impressionConfig.forEach(config => {
            if (entry.target.matches(config.selector)) {
              const params = {};
              config.event_parameters.forEach(param => {
                params[param] = entry.target.getAttribute(param) || "unknown";
              });
              // Get element's class name
              params["element_class"] = entry.target.className;
              this.addEvent("impression", params);
              this.observer.unobserve(entry.target); // Stop observing after first impression
            }
          });
        }
      });
    }, { threshold: 0.5 });

    this.impressionConfig.forEach(config => {
      document.querySelectorAll(config.selector).forEach(element => {
        this.observer.observe(element);
      });
    });

    this.observeNewImpressions();
  }

  /**
   * Observe dynamically added elements for impressions
   */
  observeNewImpressions() {
    const config = { childList: true, subtree: true };

    this.mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          if (node.nodeType === 1) {
            this.impressionConfig.forEach(cfg => {
              if (node.matches(cfg.selector)) {
                this.observer.observe(node);
              }
            });
          }
        });
      });
    });

    this.mutationObserver.observe(document.body, config);
  }

  /**
   * Periodically send events every FETCH_INTERVAL seconds
   */
  startFetchInterval() {
    setInterval(() => {
      this.sendFetchRequest();
    }, this.FETCH_INTERVAL * 1000);
  }

  /**
   * Retrieve the user ID from the dataLayer
   */
  getUserId() {
    if (!window.dataLayer || !Array.isArray(window.dataLayer)) {
      return null;
    }
    for (var i = 0; i < window.dataLayer.length; i++) {
      if (window.dataLayer[i].userId) {
        return window.dataLayer[i].userId;
      }
    }
    return null;
  }
}

// Expose the tracker with an obfuscated global name
window._et$ = new EventTracker();
